import { useState } from 'react';
import styled from 'styled-components';
import { Mutation } from 'react-apollo';
import confirmCurrentCheckoutMutation from 'graphql/mutations/confirmCurrentCheckout.graphql';
import CheckoutTemplate from 'components/templates/CheckoutTemplate';
import Redirect from 'components/atoms/Redirect';
import Alert from 'components/atoms/Alert';
import { Dl, Dt, Dd } from 'components/atoms/Dl';
import ButtonLink from 'components/atoms/ButtonLink';
import Table from 'components/pages/CartPage/Table';
import Summary from 'components/pages/CartPage/Summary';
import Textarea from 'components/atoms/Textarea';
import Form from 'components/atoms/Form';
import Button from 'components/atoms/Button';
import Nav from './Nav';

const StyledSummary = styled(Summary)`
  margin-top: 1em;
  margin-bottom: 1em;
`;

const StyledTextarea = styled(Textarea)`
  width: 100%;
  max-width: 40em;
`;

const Title = styled.h2`
  margin-top: 2.5em;

  &:first-of-type {
    margin-top: 1em;
  }
`;

const StepThree = ({ data, onSuccess }) => {
  const [comment, setComment] = useState('');

  function handleCommentChange({ target: { value } }) {
    setComment(value);
  }

  function handleError() {
    window.alert('Произошла ошибка. Попробуйте позднее.');
  }

  if (!data.currentCheckoutDeliveryInfo) {
    return <Redirect status={302} to="/cart/checkout/2" />;
  }

  return (
    <CheckoutTemplate key="ct">
      <Nav currentStep={3} data={data} />
      <Alert type="info">Проверьте все данные перед оформлением заказа.</Alert>
      <Title>1. Личные данные</Title>
      <Dl>
        <Dt>Фамилия</Dt>
        <Dd>{data.currentCheckoutPersonalData.lastName}</Dd>
        <Dt>Имя</Dt>
        <Dd>{data.currentCheckoutPersonalData.firstName}</Dd>
        <Dt>Отчество</Dt>
        <Dd>{data.currentCheckoutPersonalData.middleName}</Dd>
        <Dt>Номер телефона</Dt>
        <Dd>{data.currentCheckoutPersonalData.phone}</Dd>
        <Dt>E-mail</Dt>
        <Dd>{data.currentCheckoutPersonalData.email}</Dd>
      </Dl>
      <ButtonLink neutral inline to="/cart/checkout/1">
        Редактировать
      </ButtonLink>

      <Title>2. Доставка</Title>
      <Dl>
        <Dt>Способ доставки</Dt>
        <Dd>
          {!data.currentCheckoutDeliveryInfo.delivery
            ? 'Самовывоз'
            : data.currentCheckoutDeliveryInfo.deliveryMethod}
        </Dd>
        {data.currentCheckoutDeliveryInfo.delivery && (
          <>
            <Dt>Адрес</Dt>
            <Dd>{data.currentCheckoutDeliveryInfo.address}</Dd>
          </>
        )}
      </Dl>
      <ButtonLink neutral inline to="/cart/checkout/2">
        Редактировать
      </ButtonLink>

      <Title>3. Корзина</Title>
      <Table cartItems={data.cart.cartItems} readOnly />
      <StyledSummary cart={data.cart} />
      <ButtonLink neutral inline to="/cart">
        Вернуться в корзину
      </ButtonLink>

      <Title>4. Оформление</Title>
      <Form.Control>
        <Form.Label htmlFor="comment">Комментарий к заказу</Form.Label>
        <StyledTextarea
          id="comment"
          name="comment"
          rows={5}
          value={comment}
          onChange={handleCommentChange}
        />
      </Form.Control>

      <Mutation
        mutation={confirmCurrentCheckoutMutation}
        onCompleted={onSuccess}
        onError={handleError}
        variables={{
          comment,
        }}
      >
        {(runMutation, { loading }) => (
          <Button inline big primary onClick={runMutation} disabled={loading}>
            {loading ? 'Загрузка...' : 'Оформить заказ'}
          </Button>
        )}
      </Mutation>
    </CheckoutTemplate>
  );
};

export default StepThree;
