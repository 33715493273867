import { Component } from 'react';
import { Mutation } from 'react-apollo';
import styled from 'styled-components';
import updateCurrentCheckoutDeliveryInfoMutation from 'graphql/mutations/updateCurrentCheckoutDeliveryInfo.graphql';
import Redirect from 'components/atoms/Redirect';
import CheckoutTemplate from 'components/templates/CheckoutTemplate';
import Form from 'components/atoms/Form';
import Alert from 'components/atoms/Alert';
import Button from 'components/atoms/Button';
import Nav from './Nav';

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -1em;
`;

const Col = styled.div`
  padding: 1em;
  width: 100%;
  flex: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    width: 50%;
  }
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 1em;
`;

const options = {
  '': {
    label: 'Выберите способ...',
    data: {},
  },
  pickup: {
    label: 'Самовывоз',
    data: {
      delivery: false,
      deliveryMethod: null,
    },
  },
  cdekToWarehouseCOD: {
    label: 'CДЭК (до склада) с наложенным платежом',
    data: {
      delivery: true,
      deliveryMethod: 'CДЭК (до склада) с наложенным платежом',
    },
  },
  cdekToDoorCOD: {
    label: 'CДЭК (до двери) с наложенным платежом',
    data: {
      delivery: true,
      deliveryMethod: 'CДЭК (до двери) с наложенным платежом',
    },
  },
  cdekToWarehouse: {
    label: 'CДЭК (до склада) без наложенного платежа',
    data: {
      delivery: true,
      deliveryMethod: 'CДЭК (до склада) без наложенного платежа',
    },
  },
  cdekToDoor: {
    label: 'CДЭК (до двери) без наложенного платежа',
    data: {
      delivery: true,
      deliveryMethod: 'CДЭК (до двери) без наложенного платежа',
    },
  },
  russianPostCOD: {
    label: 'Почта России с наложенным платежом',
    data: {
      delivery: true,
      deliveryMethod: 'Почта России с наложенным платежом',
    },
  },
  russianPost: {
    label: 'Почта России без наложенного платежа',
    data: {
      delivery: true,
      deliveryMethod: 'Почта России без наложенного платежа',
    },
  },
  anotherCompany: {
    label: 'Другая транспортная компания',
    data: {
      delivery: true,
    },
  },
};

const optionKeys = [
  '',
  'pickup',
  'cdekToWarehouseCOD',
  'cdekToDoorCOD',
  'cdekToWarehouse',
  'cdekToDoor',
  'russianPostCOD',
  'russianPost',
  'anotherCompany',
];

class StepTwo extends Component {
  constructor(props) {
    super(props);

    const data = this.props.data.currentCheckoutDeliveryInfo;
    let delivery = '';
    let deliveryMethod = '';
    const address =
      (data && data.address) ||
      (this.props.data.currentUser && this.props.data.currentUser.address) ||
      '';

    if (data) {
      if (data.delivery === false) {
        delivery = 'pickup';
      } else if (data.delivery === true) {
        const foundKey = optionKeys.find(
          (optionKey) =>
            options[optionKey].data.delivery && options[optionKey].label === data.deliveryMethod,
        );

        delivery = foundKey || 'anotherCompany';
        deliveryMethod = foundKey ? '' : data.deliveryMethod;
      }
    }

    this.state = {
      delivery,
      deliveryMethod,
      address,
      error: null,
      errors: {},
      success: false,
    };
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value });
  };

  handleError = ({ graphQLErrors }) => {
    let error = 'Произошла ошибка. Попробуйте позднее.';
    let errors = {};

    if (
      graphQLErrors &&
      graphQLErrors[0] &&
      graphQLErrors[0].extensions &&
      graphQLErrors[0].extensions.code &&
      graphQLErrors[0].extensions.code === 'BAD_USER_INPUT'
    ) {
      error = graphQLErrors[0].message || null;
      errors = graphQLErrors[0].extensions.exception.errorsForFields || {};
    }

    this.setState({
      error,
      errors,
    });
  };

  handleComplete = () => {
    this.setState({
      success: true,
    });
  };

  clearErrors = () => {
    this.setState({
      error: null,
      errors: {},
    });
  };

  handleFocus = ({ target: { name } }) => {
    this.setState((state) => ({
      errors: {
        ...state.errors,
        [name]: null,
      },
    }));
  };

  handleSubmit = (event, runMutation) => {
    event.preventDefault();
    this.clearErrors();

    const defaults = options[this.state.delivery].data;

    runMutation({
      variables: {
        data: {
          ...defaults,
          deliveryMethod:
            typeof defaults.deliveryMethod === 'undefined'
              ? this.state.deliveryMethod
              : defaults.deliveryMethod,
          address: defaults.delivery ? this.state.address : null,
        },
      },
    });
  };

  render() {
    const { data } = this.props;

    if (!data.currentCheckoutPersonalData) {
      return <Redirect status={302} to="/cart/checkout/1" />;
    }

    if (this.state.success) {
      return <Redirect status={302} to="/cart/checkout/3" />;
    }

    return (
      <CheckoutTemplate>
        <Nav currentStep={2} data={data} />
        <Row>
          <Col>
            {this.state.error && <StyledAlert type="error">{this.state.error}</StyledAlert>}
            <Mutation
              mutation={updateCurrentCheckoutDeliveryInfoMutation}
              onError={this.handleError}
              onCompleted={this.handleComplete}
              update={(cache, { data: { updateCurrentCheckoutDeliveryInfo } }) => {
                cache.writeData({
                  data: { currentCheckoutDeliveryInfo: updateCurrentCheckoutDeliveryInfo },
                });
              }}
            >
              {(runMutation, { loading }) => (
                <Form
                  noBorders
                  method="post"
                  onSubmit={(event) => {
                    this.handleSubmit(event, runMutation);
                  }}
                >
                  <Form.Control>
                    <Form.Label htmlFor="delivery">
                      Способ доставки <Form.Required />
                    </Form.Label>
                    <Form.Select
                      id="delivery"
                      name="delivery"
                      required
                      value={this.state.delivery}
                      onChange={this.handleChange}
                      onFocus={this.handleFocus}
                    >
                      {optionKeys.map((optionKey) => (
                        <option key={optionKey} value={optionKey}>
                          {options[optionKey].label}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.InputErrors errors={this.state.errors.delivery} />
                  </Form.Control>
                  {this.state.delivery === 'pickup' && (
                    <Form.Control>
                      <strong>Адрес где можно забрать заказ</strong>
                      <p>
                      420202, Республика Татарстан, г. Казань, ул. Чернышевского, д. 43/2, офис 424
                      </p>
                    </Form.Control>
                  )}
                  {options[this.state.delivery].data.delivery && (
                    <Form.Control>
                      <Form.Label htmlFor="address">
                        Адрес <Form.Required />
                      </Form.Label>
                      <Form.Input
                        type="text"
                        id="address"
                        name="address"
                        required
                        value={this.state.address}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                      />
                      <Form.InputErrors errors={this.state.errors.address} />
                    </Form.Control>
                  )}
                  {options[this.state.delivery].data.delivery &&
                    !options[this.state.delivery].data.deliveryMethod && (
                      <Form.Control>
                        <Form.Label htmlFor="deliveryMethod">
                          Название транспортной компании <Form.Required />
                        </Form.Label>
                        <Form.Input
                          type="text"
                          id="deliveryMethod"
                          name="deliveryMethod"
                          required
                          value={this.state.deliveryMethod}
                          onChange={this.handleChange}
                          onFocus={this.handleFocus}
                        />
                        <Form.InputErrors errors={this.state.errors.deliveryMethod} />
                      </Form.Control>
                    )}
                  <Button type="submit" primary disabled={loading}>
                    {loading ? 'Загрузка...' : 'Далее'}
                  </Button>
                </Form>
              )}
            </Mutation>
          </Col>
          {(this.state.delivery === 'russianPostCOD' || this.state.delivery === 'russianPost') && (
            <Col>
              <Alert type="info">
                <div>
                  <strong>Внимание!</strong>
                </div>
                <p>
                  При выборе в качестве способа доставки Почты России, указание индекса в адресе
                  доставки обязательно!
                </p>
              </Alert>
            </Col>
          )}
        </Row>
      </CheckoutTemplate>
    );
  }
}

export default StepTwo;
