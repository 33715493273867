import styled from 'styled-components';
import ButtonLink from 'components/atoms/ButtonLink';
import Delimiter from 'components/atoms/Delimiter';
import CheckoutTemplate from 'components/templates/CheckoutTemplate';
import Redirect from 'components/atoms/Redirect';

const Wrapper = styled.div`
  margin-bottom: 2em;
`;

const StepZero = ({ isAuthenticated }) => {
  if (isAuthenticated) {
    return <Redirect to="/cart/checkout/1" />;
  }

  return (
    <CheckoutTemplate>
      <Wrapper>
        <h2>Я уже зарегистрирован</h2>
        <p>Войдите, чтобы использовать Ваш аккаунт для заказа.</p>
        <ButtonLink inline primary to="/signin">
          Войти
        </ButtonLink>
        <Delimiter />
        <h2>Я делаю заказ впервые</h2>
        <p>
          Зарегистрироваться и получить возможность отслеживать статусы заказов, доступ к скидкам и
          акциям, ускорить оформление следующих заказов.
        </p>
        <ButtonLink inline primary to="/signup">
          Зарегистрироваться
        </ButtonLink>
        <Delimiter />
        <h2>Заказать без регистрации</h2>
        <p>
          Разовый заказ. В этом случае Вы не сможете отслеживать статусы своего заказа и лишаетесь
          возможности получить скидки в акциях, проводимых только для зарегистрированных
          пользователей.
        </p>
        <ButtonLink inline neutral to="/cart/checkout/1">
          Перейти к заказу
        </ButtonLink>
      </Wrapper>
    </CheckoutTemplate>
  );
};

export default StepZero;
