import styled from 'styled-components';
import updateCurrentCheckoutPersonalDataMutation from 'graphql/mutations/updateCurrentCheckoutPersonalData.graphql';
import CheckoutTemplate from 'components/templates/CheckoutTemplate';
import Form from 'components/atoms/Form';
import Link from 'components/atoms/Link';
import Redirect from 'components/atoms/Redirect';
import Nav from './Nav';

const Wrapper = styled.div`
  max-width: 40em;
`;

const StepOne = ({ data }) => {
  const currentUser = data.currentUser || {};

  const getInitialValue = (field) =>
    data.currentCheckoutPersonalData !== null
      ? data.currentCheckoutPersonalData[field]
      : currentUser[field] || '';

  const fields = [
    {
      name: 'lastName',
      label: 'Фамилия',
      initialValue: getInitialValue('lastName'),
      type: 'text',
      required: true,
      showRequired: true,
    },
    {
      name: 'firstName',
      label: 'Имя',
      initialValue: getInitialValue('firstName'),
      type: 'text',
      required: true,
      showRequired: true,
    },
    {
      name: 'middleName',
      label: 'Отчество',
      initialValue: getInitialValue('middleName'),
      type: 'text',
      required: true,
      showRequired: true,
    },
    {
      name: 'phone',
      label: 'Номер телефона',
      initialValue: getInitialValue('phone'),
      type: 'text',
      required: true,
      showRequired: true,
    },
    {
      name: 'email',
      label: 'E-mail',
      initialValue: getInitialValue('email'),
      type: 'text',
      required: true,
      showRequired: true,
    },
    {
      name: 'agreement',
      label: (
        <>
          С{' '}
          <Link href="/privacy-policy" target="_blank">
            политикой конфиденциальности
          </Link>{' '}
          согласен
        </>
      ),
      initialValue: false,
      type: 'checkbox',
      required: true,
    },
  ];

  /* if (data.currentUser) {
    fields.push({
      name: 'save',
      label: 'Сохранить эти данные в личном кабинете для последующих заказов',
      initialValue: false,
      type: 'checkbox',
    });
  } */

  return (
    <CheckoutTemplate>
      <Nav currentStep={1} data={data} />
      <Wrapper>
        <Form.Factory
          mutation={updateCurrentCheckoutPersonalDataMutation}
          action="updateCurrentCheckoutPersonalData"
          update={(cache, { data: { updateCurrentCheckoutPersonalData } }) => {
            cache.writeData({
              data: { currentCheckoutPersonalData: updateCurrentCheckoutPersonalData },
            });
          }}
          fields={fields}
          submitText="Далее"
          renderOnSuccess={<Redirect to="/cart/checkout/2" push />}
          noBorders
        />
      </Wrapper>
    </CheckoutTemplate>
  );
};

export default StepOne;
