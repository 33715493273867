import styled from 'styled-components';
import ButtonLink from 'components/atoms/ButtonLink';

const Ol = styled.ol`
  display: flex;
  flex-wrap: wrap;
  margin: 0.5em -0.5em 1.5em;
  padding: 0;
  list-style-type: none;
`;

const Li = styled.li`
  margin: 0.5em;
`;

const Nav = ({ currentStep, data }) => (
  <nav>
    <Ol>
      <Li>
        <ButtonLink
          neutral={currentStep > 1}
          primary={currentStep === 1}
          disabled={currentStep <= 1}
          to="/cart/checkout/1"
        >
          1. Личные данные
        </ButtonLink>
      </Li>
      <Li>
        <ButtonLink
          neutral={currentStep > 2}
          primary={currentStep === 2}
          disabled={currentStep <= 2 && !data.currentCheckoutPersonalData}
          to="/cart/checkout/2"
        >
          2. Доставка
        </ButtonLink>
      </Li>
      <Li>
        <ButtonLink
          neutral={currentStep > 3}
          primary={currentStep === 3}
          disabled={
            currentStep <= 3 &&
            (!data.currentCheckoutDeliveryInfo || !data.currentCheckoutPersonalData)
          }
          to="/cart/checkout/3"
        >
          3. Подтверждение
        </ButtonLink>
      </Li>
    </Ol>
  </nav>
);

export default Nav;
