import { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import * as analytics from 'lib/analytics';
import checkoutQuery from 'graphql/queries/checkout.graphql';
import CheckoutTemplate from 'components/templates/CheckoutTemplate';
import LoadingPage from 'components/pages/LoadingPage';
import ErrorLoadingPage from 'components/pages/ErrorLoadingPage';
import NotFoundPage from 'components/pages/NotFoundPage';
import QueryFromCacheAfterSSRThenNetworkOnly from 'components/atoms/QueryFromCacheAfterSSRThenNetworkOnly';
import AuthenticationProvider from 'components/atoms/AuthenticationProvider';
import Redirect from 'components/atoms/Redirect';
import Alert from 'components/atoms/Alert';
import ButtonLink from 'components/atoms/ButtonLink';
import StepZero from './StepZero';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';

class CheckoutPage extends Component {
  state = {
    success: false,
  };

  handleSuccess = ({
    confirmCurrentCheckout: {
      order,
      cart,
      currentCheckoutPersonalData,
      currentCheckoutDeliveryInfo,
    },
  }) => {
    this.setState(
      {
        success: true,
      },
      () => {
        this.props.client.writeData({
          data: {
            cart,
            currentCheckoutPersonalData,
            currentCheckoutDeliveryInfo,
          },
        });
      },
    );

    analytics.order(order);
  };

  render() {
    const { data, isAuthenticated } = this.props;

    if (this.state.success) {
      return (
        <CheckoutTemplate>
          <Alert type="success">
            <div>
              <strong>Спасибо за Ваш заказ!</strong>
            </div>
            <p>Ожидайте подтверждающего звонка.</p>
            <ButtonLink neutral inline to="/">
              Вернуться в магазин
            </ButtonLink>
          </Alert>
        </CheckoutTemplate>
      );
    }

    if (!data.cart || (data.cart && data.cart.cartItems.length === 0)) {
      return <Redirect to="/cart" status={302} />;
    }

    return (
      <Switch>
        <Route
          path="/cart/checkout"
          exact
          render={(routeProps) => (
            <StepZero {...routeProps} isAuthenticated={isAuthenticated} data={data} />
          )}
        />
        <Route
          path="/cart/checkout/1"
          exact
          render={(routeProps) => (
            <StepOne {...routeProps} isAuthenticated={isAuthenticated} data={data} />
          )}
        />
        <Route
          path="/cart/checkout/2"
          exact
          render={(routeProps) => (
            <StepTwo {...routeProps} isAuthenticated={isAuthenticated} data={data} />
          )}
        />
        <Route
          path="/cart/checkout/3"
          exact
          render={(routeProps) => (
            <StepThree
              {...routeProps}
              isAuthenticated={isAuthenticated}
              data={data}
              onSuccess={this.handleSuccess}
            />
          )}
        />
        <Route component={NotFoundPage} />
      </Switch>
    );
  }
}

export default (props) => (
  <QueryFromCacheAfterSSRThenNetworkOnly query={checkoutQuery}>
    {({ data, loading, error, refetch, client }) => {
      if (loading) {
        return <LoadingPage />;
      }

      if (error) {
        return <ErrorLoadingPage />;
      }

      return (
        <AuthenticationProvider>
          {(isAuthenticated) => (
            <CheckoutPage
              {...props}
              isAuthenticated={isAuthenticated}
              data={data}
              client={client}
            />
          )}
        </AuthenticationProvider>
      );
    }}
  </QueryFromCacheAfterSSRThenNetworkOnly>
);
