import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { heading, metaDescription, metaKeywords, title } from 'constants/meta';
import MainTemplate from 'components/templates/MainTemplate';

const Title = styled.h1`
  font-size: 2em;
  font-weight: normal;
`;

const CheckoutTemplate = ({ children }) => (
  <MainTemplate>
    <Helmet>
      <title>{title.checkout}</title>
      <meta name="keywords" content={metaKeywords.checkout} />
      <meta name="description" content={metaDescription.checkout} />
    </Helmet>
    <Title>{heading.checkout}</Title>
    {children}
  </MainTemplate>
);

export default CheckoutTemplate;
